@media only screen and (max-width: 768px) {
    .Desktop_view {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        position: fixed;
        width: 100%;
        background:#ffffff;
        z-index: 1;
    }
}


@media only screen and (min-width: 768px) {
    /* For mobile phones: */

  }


  @media (min-width: 1281px) {

    .desktop_none{
        display: none;
    }

  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1024px) and (max-width: 1280px) {

    .desktop_none{
        display: none;
    }

  }

  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 769px) and (max-width: 1023px) {

    .desktop_none{
        display: none;
    }

  }