@font-face {
  font-family: "DIN Next LT Arabic Light";
  src: url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.eot");
  src: url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.svg#DIN Next LT Arabic Light")format("svg");
}
* {

  margin: 0;
  padding: 0;
  box-sizing: border-box

}

.registertext{
  margin-top: 0px;
  font-size: 20px;
  font-family: "DIN Next LT Arabic Light";
  font-weight: 600;
}
.btn:focus {

  box-shadow: none !important;
  border: none
}

.banner-section {

  overflow: hidden

}



/* copy-css-section */
.crd-copy-1 p {
  color: #766f6f;
  /* font-family: "DIN Next LT Arabic Light"; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.crd-copy-1 a{
  text-decoration: none
}


.banner-section-copy {
  
  background-image: linear-gradient(45deg, black, transparent),url(./pages/img/copy-banner.png);
  /* background-image: url(http://192.168.1.3:3000/edu-landing/static/media/copy-banner.e328fce….png); */

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content
}


@media only screen and (min-width:320px) and (max-width:785px) {


  .flexyr {

/* flex-wrap: wrap; */
display: none!important

  }

  #fonter{
    padding-top: 70px!important
  }

}


.img-1{
  background-image: url(./pages/img/image-copy-01.png);
  width: 100%;
  height:200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px
}


.img-1 h5{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal

}


.img-1 p{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase
}



.img-2{
  background-image: url(./pages/img/image-copy-02.png);
  width: 100%;
  height:200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px
}


.img-2 h5{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal

}


.img-2 p{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase
}




.img-3{
  background-image: url(./pages/img/image-copy-03.png);
  width: 100%;
  height:200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px
}


.img-3 h5{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal

}


.img-3 p{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase
}



#icons-crd img{
  height:80px
}

.copyr img{
  width:100%
}

.copyr h5{
  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase
}

#copyr2{
color: #000;
font-family: "DIN Next LT Arabic Light";
font-size: 29px;
font-style: normal;
font-weight: 700;
line-height: normal
}




#logr{

color: black;
font-family: "DIN Next LT Arabic Light";
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
/* width:100%; */
text-align: start;
border: 2px solid #3C6EFD

}




.banner-section-copy h5{
  color: #FFF;
/* text-align: center; */
font-family: "DIN Next LT Arabic Light";
font-size: 29px;
font-style: normal;
font-weight: 700;
line-height: normal
}

.banner-section-copy p{
  color: #FFF;
/* text-align: center; */
font-family: "DIN Next LT Arabic Light";
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal
}

.cpr-2{
  color: #FFAA01;
font-family: "DIN Next LT Arabic Light";
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: normal
}

#cpr-3{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: normal
}


.flexyr p{
  color: #FFF;
font-family: "DIN Next LT Arabic Light";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: center;
margin-top: 5px
}


.banner-copy-form{
width: 100%;
height:fit-content;
border-radius: 10px;
background: #FFF
}

.banner-copy-form h5{

  color: #333;
font-family: "DIN Next LT Arabic Light";
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: normal

}

.banner-copy-form p{

  color: #757575;
font-family: "DIN Next LT Arabic Light";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal

}

.banner-copy-form label{
  color: #333;
  font-family: "DIN Next LT Arabic Light";
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.form-select{
  color: #757575;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal 
  /* padding: 5px; */
}

.form-select:focus {
   border-color:none!important;
   outline: none!important;
   box-shadow: none!important
}


.cp-but button{
  width:100%
}

 select{
  /* width:100%;
  border-radius: 3px;
background: #F2F2F2; */
}
/*  */










/* HEADER SECTION */


/* upper-top-header */


/* TOP HEADER SECTION */
#naver-link
{ text-decoration: none;
color: #020A1A !important;
font-family: "DIN Next LT Arabic Light";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
/* padding-top: 5px; */

}

.upper-header{



}

.upper-header p {
  /* text-decoration: none; */
font-family: 'Inter', sans-serif;
font-size: 13px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: #fff

}

.upper-header img{
  width:16px;
  height: 16px
}

a{
  text-decoration: none!important;
}


.header-next-2{
 background-color: #F5F5F5
}


/*  */




.firster {

  border-right: 2px solid #8080803b;
  height: 42px;
  margin-left: 21px

}

.t-hc p {

  color: #020A1A;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 700

}

.top-header {

  background-color: #B2D6DC;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #8080803b

}



.top-header2 {

  background-color: #B2D6DC;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #8080803b

}

.header-next {

  background-color: #B2D6DC;
  width: 100%;
  height: fit-content
}

#btn-1 {

  border-radius: 5px;
  background: #2D69F0;
  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal

}


#navbarDropdown {

  text-decoration: none;
color: #020A1A !important;
font-family: "DIN Next LT Arabic Light";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;

}


#sbt {

  height: 39px;
  /* background-color: #0d6efd; */

}

.dbt {

  background: #B2D6DC !important;
  border: none !important

}

/* END HEADER SECTION */



/* SECTION1 (HOMEPAGE) */


.banner-section {

  background-image: url(./img/education-banner-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content
}

.text1 {

  color: #020A1A;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.banner-section h3 {

  color: #020A1A;
  font-family: "DIN Next LT Arabic Light";
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal

}

.banner-section p {

  color: #020A1A;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal

}

.banner-section img {

  width: 100%
}

.btnr {

  color: #020A1A;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.btnr-next {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.st-but {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.st-buts {

  color: black;
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #2D69F0

}

.st-buts svg {

  color: black !important

}



.st-butss {

  color: black;
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.30)

}

#svr svg {

  fill: black !important

}


.next-pr p {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal

}

.nexter-hr h5 {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal

}

/* END SECTION1 */


/* SECTION2 */

.section2 {

  background: #F8F8F8;
  width: 100%;
  height: fit-content

}

.section2 h5 {

  color: #020A1A;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}


.mybss {

  width: 100%

}

.boxess {

  flex-wrap: wrap

}


/* .boxes1{
  min-width:250px;
} */

/* END SECTION2 */


/* SECTION3 */

.section3 h3 {

  color: #000;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}

.section3 p {

  line-height: normal;
  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

}

.texter h5 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.texter p {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.texter1 {

  border-radius: 5px;
  border: 1px solid rgba(204, 204, 204, 0.30);
  background: #FFF;
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.07)

}

.texter1 h6 {
  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.texter1 p {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.texter-pic img {

  width: 100%

}


/* END SECTION3 */

/* SECTION4 */

.section4 {

  background-image: url(./img/predictbg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content
}

.section4 h3 {

  color: #FFF;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}

.section4 p {

  color: rgba(255, 255, 255, 0.60);
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

#crd {

  background-color: #020A19 !important;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.30);
  background: rgba(255, 255, 255, 0.02);
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.07)

}

#crd h5 {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

#crd button {

  /* color: #FFF; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

/*END SECTION4 */


/* SECTION5 */

.section5 h3 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}

.latest {

  color: #000;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.section5 p {

  color: #777;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.crdr1 h5 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.crdr1 p {

  color: #777;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.next-para {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.next-para a {

  color: #2D69F0;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none

}


/* END SECTION5 */

/* SECTION6 */

.section6 {

  /* background: rgba(184, 221, 227, 0.30); */
  background-image: url(../src/pages/img/background-grad.png);
  width: 100%;
  height: fit-content;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat

}

.admission-process {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}

.section6 h3 {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.prs {

  color: rgba(255, 255, 255, 0.50);
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase

}

.carders1 h6 {

  color: #FFF;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal
}

#nexter-p {

  color: rgba(255, 255, 255, 0.70);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.carders1 {

  background: none !important;
  border-radius: 10px;
  border: 4px solid rgba(255, 255, 255, 0.30);
  width: 100%;
  min-height: 350px !important

}


/* END SECTION6 */

/* SECTION7  TAB PILLS */

#heads {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}

#explore {

  color: #000;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

#my-p {

  color: #777;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}


.tabber {

  border-radius: 5px !important;
  background: #EAF5F7 !important;
  width: 100%
}

.tabber button {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

#pills-home-tabs {

  /* color: #222!important; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px !important;
  border: 1px solid #777 !important

}

#pills-tab:active {

  color: white !important

}

/* 
.nav-link{
  border-radius: 5px!important;
border: 1px solid #777!important;
} */


#pills-profile-tabs {

  /* color: #222; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px !important;
  border: 1px solid #777 !important

}


#pills-contact-tab {

  /* color: #222; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px !important;
  border: 1px solid #777 !important

}


#pills-home1-tab {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px !important;
  border: 1px solid #777 !important

}

#pills-profile1-tab {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px !important;
  border: 1px solid #777 !important

}

#pills-contact1-tab {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px !important;
  border: 1px solid #777 !important

}



/* END SECTION7 TAB PILLS*/

/* SECTION8 */
.sticker h5 {

  color: #222;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.sticker button {

  color: #FFF;
  text-align: center;
  font-family: "DIN Next LT Arabic Light";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

.pstr img {

  position: absolute;
  top: 0;
  /* left: 108px; */
  right: 0px

}

.sticker button {

  position: absolute;
  left: 0;
  bottom: 16px

}



#nav-home-tab {

  border-radius: 5px;
  border: 1px solid #777 !important;
  color: #222;
  width: 97px

}


#nav-profile-tab {

  border-radius: 5px;
  border: 1px solid #777;
  color: #495057

}


#nav-contact-tab {

  border-radius: 5px;
  border: 1px solid #777;
  color: #495057

}


.nav-tabs {

  --bs-nav-tabs-border-width: 0px !important;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border: none !important

}


/* END SECTION8 */


/* FOOTER SECTION */


.footer1 {

  background: #020A1A;
  width: 100%;
  height: fit-content

}

.footer1 h5 {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.footer1 a {

  color: rgba(255, 255, 255, 0.60);
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-decoration: none

}

.footer2 {

  background: #020814;
  /* opacity: 20%; */
  width: 100%
}

.footer3 {

  background: #020A1A;
  width: 100%;
  height: fit-content

}

.footer3 p {

  color: rgba(255, 255, 255, 0.60);
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}

.footer3 h5 {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal

}

.footer3 p {

  color: rgba(255, 255, 255, 0.60);
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal

}


/*END FOOTER SECTION */


/* COLLEGE LIST PAGES  */

#crumb a {

  color: #000 !important;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none

}

.list-section h6 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal

}

.list-section p {

  color: #2D69F0;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal

}

#next-text {

  color: #444;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

#next-text a {

  color: #2D69F0;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

}


/* section3 */
.list-section {

  background: #F8F8F8;
  width: 100%;
  height: fit-content

}

.lister-card h6 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.lister-card p {

  color: #666;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

#clr {

  color: #2D69F0;

}

#btnr {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #777;

}

.iconerss-card img {

  position: absolute;
  top: 30px;

}

.iconerss-card h5 {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
 
}

.mysvg svg {

  color: #FFBF1B;

}

#one-svg svg {

  color: #D9D9D9;

}

.mysvg {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.para1 {

  color: #333;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


.ugc {

  color: #333;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.other-para {

  color: #2D69F0;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.icon-btn button {

  width: 100%;
  text-align: start;
  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 40px;

}

.icon-btn button img {

  text-align: end !important;

}
#btnrs1:hover{
  background: #ff9900;
transition: 0.4s;
}

#btnrs1 {
  background: #FF9800;
  border: 1px solid;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, .4);
  color: #000000;
  font-weight: 700;
  border-radius: 25px;
  /* color: #FFF; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;

  text-align: start;
  /* background-color: #0c6efd !important; */
  /* border: 1px solid #0c6efd !important; */

}



#btnr1 {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  text-align: start;


}



#btnr2 {

  color: #2D69F0;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #2D69F0;

}

.iconers-card {

  background: #F8F8F8;
}


.iconerss-card {

  background: #F8F8F8;

}

#icons-crd {

  border-radius: 5px;
  border: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  /* height: 321px; */

}

/* form.examples input[type=text] */
.example input {

  padding: 8px;
  border: 1px solid #CBCACA;
  border-right: none;
  float: left;
  width: 80%;
  background: #FFFFFF;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
  color: #777;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */

}

form.example button {

  float: left;
  width: 18%;
  padding: 8px;
  background: #FFFFFF;
  font-size: 17px;
  border: 1px solid #CBCACA;
  border-left: none;
  cursor: pointer;
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}



.accordion-item {

  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
  border: none !important;

}


.lab {

  color: #333;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.accordion-button:not(.collapsed) {

  /* color: var(--bs-accordion-active-color); */
  background-color: white !important;
  /* background-color: var(--bs-accordion-active-bg); */
  /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
  box-shadow: none !important;

}

.accordion-button:focus {

  z-index: 3;
  /* border-color: var(--bs-accordion-btn-focus-border-color); */
  border: 1px solid white !important;
  outline: 0;
  /* box-shadow: var(--bs-accordion-btn-focus-box-shadow); */

}

.header-accord {

  color: #000 !important;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


button:focus {

  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  /* box-shadow: var(--bs-accordion-btn-focus-box-shadow); */
  box-shadow: none !important;

}



.accordion-button {

  padding: 0 !important;

}

.accordion-body {

  padding: 0 !important
}

@media only screen and (min-width:768px) and (max-width:991px) {

  .iconerss-card img {
    position: absolute;
    top: 25px;
  }

}

@media only screen and (min-width:450px) and (max-width:768px) {

  .iconerss-card img {
    position: absolute;
    top: 45px;
    top:29px;
  }

  .university-section {
    padding-top: 6rem !important;
  }

}

@media only screen and (min-width:320px) and (max-width:768px) {

  .university-section {
    padding-top: 6rem !important;
  }

  .list-section {
    padding-top: 6rem !important;
  }

}

/* END COLLEGELIST PAGES */



/* COLLEGELIST2 PAGES */

.iconers-card h5 {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}


.iconer-card img {

  position: absolute;
  top: 30px;

}



@media only screen and (min-width:320px) and (max-width:456px) {

  #dit-res {
    margin-top: 1.5rem !important;
  }

}



/* END COLLEGELIST2 PAGES */



/* UNIVERSITY PAGES */
.university-section {

  background: #B8DDE3;
  width: 100%;
  height: fit-content;

}

.third-section p {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.mysvgs svg {

  color: #FFBF1B;

}

.mysvgs {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

#btnrs2 {

  color: #2D69F0;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  /* border: 1px solid #2D69F0; */
  background-color: white;

}

.apply-content {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

#more {

  display: none;

}

#myBtn {

  background-color: white;
  border: none;
  color: #0d6efd;

}

.mytab {

  width: fit-content;

}


.overview h2 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.overview h5 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.overview h6 {

  font-size: 15px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.overview-para p {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.overview-para ul li {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.overview h4 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;

}


.overview h6 {

  font-size: 17px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.table td {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.table td ul li {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.table td ol li {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.table-section {

  background-color: #E9ECFF;

}

.tab-section h5 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.table-section ul li {

  color: #3f53d9;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;

}

.divider-crd h5 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.overview p {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.overview ul li {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


.divider-crd a {

  color: #3f53d9;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

}

.divider-crd p {

  /* color: #3f53d9; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

}

.ann {

  color: #717171;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.res {

  font-weight: 700;
  display: block;
  color: #f5a041
}

.divider-crd button {

  /* background-color: white; */
  /* border: 2px solid #3f53d9 !important; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* border-radius: 2px !important; */
  /* color: #3f53d9; */

  width:178px;
  height:44px;
  padding: 10px;
  border-radius: 5px!important;
  border: 2px solid #CD2D2D!important;
  color:#CD2D2D;
  gap: 10px;
  background-color: #fff;
}


.divider-crd button:hover {

  background-color: #3f53d9;
  /* border: 2px solid #3f53d9!important; */
  color: white;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.fee-section {

  background-color: #E9ECFF;
  width: 100%;
  height: fit-content
}

.fee-section-2 {

  border: 4px solid #abb2e7 !important;

}

.fee-section-2 h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.fee-section-2 p {

  font-size: 15px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.fee-section-2 button {

  background-color: #3f53d9;
  font-size: 15px;
  color: #fff;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;

}


#pills-home-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-home-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-profile-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-profile-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}


#pills-new-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-new-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-new2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-new2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}


#pills-rev-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-rev-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-rev2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-rev2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-add-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-add-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-add2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-add2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-gallery-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-gallery-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-gallery2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-gallery2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}



#pills-camp-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-camp-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-camp2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-camp2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-course-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-course-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-course2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-course2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}


#pills-uni-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-uni-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-uni2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-uni2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}


#pills-cutoff-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-cutoff-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-cutoff2-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-cutoff2-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}


#pills-homer-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important; */
  border-radius: 0px !important;
  color: #222;

}

#pills-homer-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

#pills-profiler-tab {

  border: none !important;
  background: white !important;
  /* border-bottom: 3px solid blue!important;*/
  border-radius: 0px !important;
  color: #222;

}

#pills-profiler-tab:hover {

  border: none !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  transition: 5s;

}

.card-linker {

  font-size: 15px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  line-height: 2;

}

.card-linker:hover {

  color: blue
}

.card-titles {

  font-size: 14px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.courses-crd {

  /* margin: 0 10px 10px 0!important; */
  border-radius: 25px !important;
  /* padding: 5px 18px!important; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.news-crd1 {

  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.news-crd2 {

  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: black;

}

.course-section h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.cont-section h5 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.cont-section p {

  font-size: 16px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.cont-section {

  background-image: url(../src/pages/img/contactbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}


.form-group textarea::placeholder{
  padding-top: 10px
}

.form-group textarea{
  border: 1px solid #ced4da;
  border-radius: 0.375rem
}





.backs-form p{
  font-family: "DIN Next LT Arabic Light";
  font-Weight:400;
  font-size: 15px;
  /* line-height:18.86px; */
  color: #333333
}

.backs-form h5{

  font-family: "DIN Next LT Arabic Light";
  font-weight: 700;
  font-size:15px;
  /* line-height: 18.86px; */
  color:#666666

}





.news-section h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal
}

.slider-section h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal
}



.faq-section h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal
}

.accordion-button {

  font-size: 16px;
  color: #222 !important;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal
}

.accordion-body {

  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: black
}

.para1 button {

  font-size: 14px;

}


#mini-cr {

  background-color: #FBFBFC;
  width: 100%;
  height: fit-content;

}


#art-btn {

  background-color: #E9ECFF;
  height: fit-content;
  width: fit-content;
  border: 1px solid #d9d9e5;
  border-radius: 4px;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: black;

}

.sp-but {

  width: 100% !important;
  color: white;
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.sp-buts {

  width: 100% !important;
  color: white;
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}


.reg-section h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.by-section h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.etp-section1 h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.course-section1 h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

/* END UNIVERSITY PAGES */


/* MEDIA QUERIES */

@media (min-width:767px) and (max-width:801px) {

  form.example button {
    float: left;
    width: 20%;
    padding: 0px;
  }

}

@media (min-width:320px) and (max-width:990px) {

  .iconer-card img {
    position: absolute;
    top: 0;
  }

  .back-form {
    display: none;
  }

  .back-nextform {
    /* width: 100%; */
    /* justify-content: center; */
  }

}

.ph-forms textarea{
  width:100%
}


@media (min-width:320px) and (max-width:570px){
.banner-copy-form{
  display: none!important;
}

}


/* carousel css */
/* 

#app {
  height: 100%;
}
.swipers{
  position: relative;
  height: 100%;
}

.swipers {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

 */


.swiper-pagination {

  position: none !important;
  text-align: center;
  transition: 300ms opacity;

}

/* my-slider-section */


.swiper-pagination-progressbar {

  /* background-color:#fff!important; */
  position: absolute;

}




/* pro sidebar */
.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {

  width: 0% !important;
  height: 0px !important;
  left: 0;
  top: 0;

}


.ph-forms input {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;

}


#continent {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  line-height: 1.5;
  /* color: #212529; */
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;

}

#form p {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.fr-but button {

  width: 100% !important;

}

.last-para {

  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

#form a {

  text-decoration: none;
}



#pills-tab {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

}


.logers h5 {

  color: #FFF;
  font-family: "DIN Next LT Arabic Light";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #E9AC37;
  border-radius: 5px;
  /* Padding:2px, 4px, 2px, 4px; */
  Gap: 10px;

}

.logers p {

  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

}



.back-form {

  /* border-radius: 5px 0px 0px 5px;
  background: #23459E;
  width: 50%;
  height: 510px; */
  background-image: url('../src/pages/img/ctr.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.back-form img {

  width: 100%;
  height: 510px;

}

.full {

  width: 100%;
  height: 510px;
  background-color: #fff;

}

.back-nextform {

  width: 100%;
  height: 500px;
  background-color: #fff;
  border-top-right-radius: 5px 0px 0px 5px;
  border-bottom-left-radius: 5px 0px 0px 5px;

}

.back-nextform h5 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;

}


/* modal */
.modal-body {

  padding: 0 !important;

}

/* end modal css */


#home-tabst {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}


#profile-tabst {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

#contact-tabst {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

.stream-section p {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

#exam-tab {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

#course-tab {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

#degree-tab {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

#degree {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


#exam {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


#course {

  color: #212529;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.cut-off-section h5 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.dropdown-item {

  text-decoration: none !important;

}

#ubt {

  height: 38px;

}

.justt {

  display: flex;
  align-items: center;
  height: 300px;

}

.rater h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.review-side h5 {

  font-size: 15px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.review-side p {

  font-size: 14px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.review-side button {

  font-size: 14px;
  color: white;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


.rater svg {

  color: goldenrod;

}

.likeBtn {

  padding: 8px 16px;
  font-weight: 500;
  background-color: #4aba8e;
  border: 2px solid #4aba8e;
  margin: 15px 0;
  border-color: #4aba8e !important;
  display: flex;
  align-items: center;
  color: white;

}

.thumbr h5 {

  font-size: 16px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.thumbr ul li {

  font-size: 15px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


.thumbr p {

  font-size: 15px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


.dropdown-menu a {

  text-decoration: none;

}

.dropdown-menu {

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  border: none !important;
  /* width: 200PX;
  height: 170px; */

}


.gvrPml {

  height: 40px;
  width: 48px;
  position: absolute;
  left: 0;
  top: 7px !important;

}

.ilZVLX {

  margin-top: 6px;

}

.bncmtB {

  z-index: 999;
  overflow: hidden;
  position: absolute;
  /* top: 56px; */
  top: 30px;
  width: 268px;
  width: 100% !important;

}

.closer {

  position: absolute;
  left: 96%;
  top: 9px;

}



#modular {

  height: 250px !important;

}


.overview ul {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.sm-p {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.int {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


#nav-tab button {

  font-size: 14px !important;
  /* color: #000; */
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}


/* NEWS SECTION */

.small-font {

  color: #717171;
  font-size: 14px;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */

}

.big-font a {

  color: #717171;
  font-size: 16px;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: bolder;
  /* line-height: normal; */
  text-decoration: none;

}

.big-font a:hover {

  color: #0d6efd;

}

.newset p {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.newset img {

  width: 100%;
  height: 160px
}


.newset h2 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.quester h1 {

  font-size: 21px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.quester h3 {

  font-size: 18px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}


.divider-crds-desk button {
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 2px !important;
  /* color: #3f53d9; */
  width:178px;
  height:44px;
  border-radius: 5px;
  border: 1.8px solid #CD2D2D!important;
  color:#CD2D2D;
  gap: 10px;
  background-color: #fff;
}


.divider-crds-desk button:hover{
  background-color: #fff;
  color:#CD2D2D;
 
}

.divider-crds button:hover {

  background-color: #3f53d9;
  /* border: 2px solid #3f53d9!important; */
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}


.atg {

  width: 50px;
  height: 50px;
  margin-right: 25px;
  /* background-color: var(--lightTextColor); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  background-color: rgb(233, 108, 40)
}


.com p {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.com svg {

  color: #717171;

}

.com h6 {

  font-size: 16px;
  color: #222;
  font-family: "DIN Next LT Arabic Light";
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.svgrs {

  color: #3f53d9
}

.svgrs2 {

  color: #0c642d;

}

/* .svgrs2{
  background-color: black;
  height: 30px;
  width: 30px;
  padding: 20px;
} */
.modals {

  /* --bs-modal-zindex: 1055; */
  --bs-modal-width: 941px;
}

@media only screen and (min-width:768px) and (max-width:992px) {

  .divider-crds button {
    font-size: 12px;
  }

  .t-hc p {
    font-size: 12px;
    font-weight: 500;
  }

}

@media only screen and (min-width:320px) and (max-width:500px) {

  .divider-crddesk button {
    background-color: white;
    border: 1px solid #3f53d9 !important;
    font-family: "DIN Next LT Arabic Light";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 2px !important;
    color: #3f53d9
  }

  .svgrs {
    color: #3f53d9
  }

  .svgrs2 {
    color: #0c642d;
  }

}

@media only screen and (min-width:500px) and (max-width:768px) {

  .divider-crddesk button {
    /* background-color: white; */
    /* border: 2px solid #3f53d9 !important; */
    font-family: "DIN Next LT Arabic Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* border-radius: 2px !important; */
    color: #3f53d9;
    width:178px;
    height:44px;
    padding: 10px;
    border-radius: 5px!important;
    border: 2px solid #CD2D2D!important;
    color:#CD2D2D;
    gap: 10px;
    background-color: #fff;
  }

  .ph {
    margin-left: 20px;
  }

  .wh {
    margin-left: 20px;
  }

  .svgrs {
    color: #3f53d9;
  }

  .svgrs2 {
    color: #0c642d;
  }
}


@media (max-width:375px) {

  .desktop_none img {
    /* padding-right: 20px;
    border: none !important; */
    width: 100px !important;

  }

}



@media (min-width:375px) and (max-width:769px) {

  .desktop_none img {
  
    width: 138px !important;

  }

}


/* blink */


.blinkrss {

  animation: blinker 1.5s linear infinite;
  /* color: red; */
  font-family: sans-serif;

}

@keyframes blinker {

  50% {
    opacity: 0;
    height: 27px;
    width: 27px;

  }
}


.imr img {

  width: 50px;

}

.imrun img {

  width: 57px;

}

.card-img-tops {

  width: 100%;
  height: 98px;

}

.texter {

  text-align: left !important;

}

.imgrsd img {

  width: 100%
}

.mbbs-card {

  border: none !important;
  background: transparent;

}

.ig-card {

  min-height: 330px !important;
  /* width: 100%; */

}

.mbbs-card:hover {

  border-radius: 5px 5px 0px 0px !important;
  border: 0px solid rgba(0, 0, 0, 0.10) !important;
  /* border-bottom: none!important; */
  /* background: rgba(234, 245, 247, 0.50); */

}

.allmbbs {

  border: 1px solid rgba(0, 0, 0, 0.10);
  margin-top: -3px;

}


.colmdr {

  padding: 0px 0px 2px 0px !important;

}

.colmdr button {

  padding: 0px;

}



.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {

  color: #495057;
  /* background-color: red; */
  /* border-color: #dee2e6 #dee2e6 #fff; */
  border-radius: 5px 5px 0px 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.10) !important;
  /* border-bottom: none; */
  background: rgba(234, 245, 247, 0.50);
  border-bottom: 1px solid #fff !important;
  position: relative !important;
  top: 0px !important;

}



.testerbox {

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  min-height: 363px !important;
  border: none !important;


}

.testersection h3 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}


.testerbox h5 {

  font-size: 19px;
  color: #4B4E51
}

.testerbox p {

  color: #777;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

/* ABOUT US PAGE */

.abt-section {

  background-image: url(../src/pages/img/aboutusbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}


.abt-section-news {

  background-image: url(../src/pages/img/news-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}




#srv-section {

  background-image: url(../src/pages/img/svc.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

.abt-section h3 {

  color: #000;
  /* text-align: center; */
  font-family: "DIN Next LT Arabic Light";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

#brdrs {

  height: 6px;
  width: 100%;
  color: #333;

}

.abt-2 p {

  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.abt-2 h5 {

  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.abt-2 ul li {

  color: #333;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.bds {

  border-bottom: 5px solid #333;
  width: 70px;

}


.bdsss {

  border-bottom: 5px solid white;
  width: 70px;

}

.bds-news {

  border-bottom: 5px solid #333;
  width: 70px;
  position: relative;
  left: 47%;
  right: 50%;

}

.bdsss-news {

  border-bottom: 5px solid white;
  width: 70px;

}


@media (min-width:320px) and (max-width:768px) {

  .abt-section h3 {
    padding-top: 100px;
  }

  .cont-section h3 {
    padding-top: 100px;
  }

  .mds {
    align-items: end !important;
  }

  .mobile-menu-icon {
    margin-top: 6px !important;
  }

  .overview {
    display: none;
  }

}

.Desktop_view {

  z-index: 9 !important;

}


.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {

  color: #fff;

}

.bi-facebook {

  color: #1877F2;

}

.bi-twitter {

  color: #1DA1F2
}

.bi-linkedin {

  color: #0077b5;

}

.bi-instagram {

  color: #405DE6;

}

.bi-whatsapp {

  color: #075e54;

}

.dbt img {

  width: 42px !important
}


@media (min-width:320px) and (max-width:802px) {

  .foot-res1 img {
    /* margin-top: 11px; */
  }

  .foot-res2 {
    /* margin-top: 11px; */
  }

}


.dropdown-item {

  /* border-radius: 5px; */
  /* background: #F1F1F1; */
  color: #000;
  font-family: "DIN Next LT Arabic Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 0px 10px 12px;
  margin-top: 0 !important;

}





#nav-overview-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-courses-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-program-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}



#nav-admission-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}


#nav-scholarship-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}


#nav-placement-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-cutoff-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-campus-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-gallery-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-reviews-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}

#nav-news-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}



#nav-quest-tab.active {

  background: rgba(234, 245, 247, .5);
  border: 1px solid rgba(0, 0, 0, .1) !important;
  border-radius: 5px 5px 5px 5px !important;

}


#acnav nav {

  border: 2px solid gray;
  padding: 2px;

}

.bncmtB>ul>li:last-child {

  border-radius: none !important;

}



/* .modal-xl {
  --bs-modal-width: 830px!important;
} */


/* mobile designing */



.sections2 h5 {

  color: #020A1A;
  font-family: "DIN Next LT Arabic Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}



.myc1{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%!important;
  height: 200px;
  width: 100%;

}



.imprs1{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  text-align: center;
}



.imrs img {

  width: 50px;

}



@media only screen and (min-width:320px) and (max-width:767px) {

  .section2 {
    display: none;
   }
   .first-section{
     padding-top: 50px!important;
   }
 }
 
 
 
 @media only screen and (min-width:767px) and (max-width:1991px) {
 
   .sections2{
   display: none;
   padding-top: 50px!important;
  }
 }


 @media only screen and (min-width:320px) and (max-width:780px){
  .respond{
    padding-top: 0px!important;
    margin-top: -19px!important;
  }

  .banner-copy-form{
    margin-bottom: 10px!important;
  }

 }


 
.sections2{
  margin-top: 30px;
}



.nav-link{
  text-decoration: none!important;
}



/* nav tab pills */
#dropdownMenuButton2{
  color: #fff;
  font-family: "DIN Next LT Arabic Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-left: 30px;
}


.dropdown-menu2 li{
  list-style: none;
}





.swiper-button-next, .swiper-rtl .swiper-button-prev::before {
  right: 60px!important;
  left: auto;
}


.swiper-button-prev, .swiper-rtl .swiper-button-prev::after {
  left: 60px!important;
}



@media only screen and (min-width:785px) and (max-width:1995px) {

.mob-swi{
  display: none;
}

}

@media only screen and (min-width:320px) and (max-width:785px) {

  .mob-swi1{
    display: none;
  }
  }

  /* css ani*/

.border-rightfornav{
  border-right: 1px solid #ccc;
}
.nav-padding{
  /* padding-top: 3px;
  padding-bottom: 3px; */

}
.nav-padding:hover{
  background-color: #ccc;
  transition: 0.5s;
}
.upper-header{
  background-image: url(./components/topnav.svg);
}


  .wave {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    background-color: #f0f0f0;
  }
  
  .wave:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 40px;
    width: 100%;
    height: 60px;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, #f0f0f0 50%, rgba(255,255,255,0) 100%);
    animation: wave-animation 2s infinite linear;
  }
  
  @keyframes wave-animation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
.header-toppadding{
  padding-top: 14px;
}

.wave_talk {
  position: relative;

  overflow: hidden;
  background-color: #f0f0f0;
}

.wave_talk:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 40px;
  width: 100%;
  height: 60px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f0f0f08a 50%, rgba(255, 255, 255, 0) 100%);
  animation: wave-animation 2s infinite linear;
}

@keyframes wave-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 590px){
  /* .register model */
  .closebutton {
    position: relative;
    left: 95% !important;
    top: 5px;
    z-index: 111;
    background: #ff8086;
    padding: 0;
    margin: 0;
    border-radius: 50%;
}

/* close */
  .border_before::before{
    display: none;
  }
  .imrs img{
    width: 30px;
  }
  .mob-hide{
    display: none;
  }
  .sections2 h5 {

    font-size: 12px;
    position: absolute;
    top: -12px;
    left: -2px;
    right: -2px;
    background: #550485;
    padding: 3px;
    color: #fff;

}
.p-head{
  line-height: 29px;
    font-size: 13px;
    font-weight: 600;
}
.myc1 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100% !important;
  height: 86px;
  width: 100%;
}
  .banner-section-copy {
    /* background-image: linear-gradient(45deg, black, transparent), url(http://192.168.1.3:3000/edu-landing/static/media/copy-banner.e328fce….png); */
    /* background-image: url(http://192.168.1.3:3000/edu-landing/static/media/copy-banner.e328fce….png); */
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 440px;
    padding: 59px 0px;
    text-align: center;}
}

.icon-width svg{
  width: 47px;
  height: 31px;
}

.dropdown-menu {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out; /* Define transition */
}

.dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  margin-top: 1px;
  transition: 1s ease all;
  --bs-dropdown-padding-y: 0.0rem;
  margin-left: -24px;
}
#dropdown-li.show{
  width: 100%;
}

#dropdown-li li{
  border-bottom: 1px solid #ccc;
}
#dropdown-li.show::before {
  content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    left: 45%;
    top: -4px;
    transition: 1s ease all;
    background: #ffffff;
    z-index: 10;
    transform: rotate(45deg);
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: #e9ecef96;
  transition: 1s eassy-all;
}
.plx-4{
  padding-right: 1.5rem;
  padding-left: 1.5rem !important;
}


@media (min-width: 1024px) and (max-width: 1280px) {

  .plx-4{
    padding-right: 1.5rem;
    padding-left: 0rem !important;
  }

}

.register label{
  padding: 0 0 6px 0 !important;
}
#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  font-family: Raleway;
  padding: 40px;
  width: 70%;
  min-width: 300px;
}

h1 {
  text-align: center;  
}

input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
.tab {
  display: none;
}

button {
  background-color: #04AA6D;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

#prevBtn {
  background-color: #bbbbbb;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;  
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #04AA6D;
}
.circal{
   font-size: 19px;
    border: 1px solid #cccccc96;
    width: 50px;
    height: 50px;
    padding: 10px 0px;
    border-radius: 50%;
    background: #cccccc2b;
}

#registration label,input{
  font-family: "DIN Next LT Arabic Light";
  font-weight: 500;
}

.spamred{
  color: red;
}
.submit_button{
  width: 107px !important;
  margin: auto;
}

.closebutton{
  position: relative;
  left: 99%;
  top: 5px;
  z-index: 111;
  background: #ff8086;
  padding: 0;
  margin: 0;
  border-radius: 50%; 
}
.close_icon_width{
  width: 26px;
  height: 25px;
  margin-top: -4px;
  cursor: pointer;
  z-index: 99999;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #FF5722;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgb(13 110 253 / 0%);
}

.react-tel-input .form-control {
  width: 100%;
}
.coursor{
  cursor: pointer;
}



/* CSS */
.custom-dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-left: -24px;
  margin-top: 1px;
  width: 100%;
}

.custom-dropdown-menu.show {
  display: block;
}

#dropdown-india::before {
  content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    left: 45%;
    top: -4px;
    transition: 1s ease all;
    background: #ffffff;
    z-index: 10;
    transform: rotate(45deg);
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.f-16{
  font-size: 16px;
  font-weight: 600px;
}
/* Add any additional styles for your dropdown items here */
table {
  caption-side: bottom;
  border-collapse: unset;
  border: 1px solid;
}
th {
  border: 1px solid #cccc;
}

.table-bordered>:not(caption)>*>* {
  border-width: 1px 1px;
}
.table-bordered>:not(caption)>*>* {
  border-width: 1px 1px;
  color: #0b25cf;
}

.navtop{
    display: flex;
    justify-content: space-around;
    /* position: sticky;
    top: 5px;
    left: 0;
    right: 0; */
    z-index: 9999;
    background: #ccc;
    padding: 8px 0px;
}
#introduction .active{
  background-color: red;
}
#courses .active{
  background-color: rgb(34, 6, 78);
}
/* .list-none li{
  display: flex;
} */
/* END ******************************************************************APARNA********************************** */