body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "DIN Next LT Arabic Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iconerss-card img{
width: 80px !important;
height: 80px !important;
}
.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  background-color: #fff;

  border: 1px solid #ccc;
}

.submenu {
  display: none;
  position: absolute;
  left: 100%;
  width: max-content;
  background-color: #fff;
  border: 1px solid #ccc;
}


.dropdown-item {
  clear: both;
  width: 100%;
  color: #333;
  text-decoration: none;
  padding: 5px;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
  
}

.dropdown-item a {
  color: #333;
  padding: 0px 5px;
  text-decoration: none;
  color: #020A1A !important;
  font-family: "DIN Next LT Arabic Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dropdown-item a:hover {
  color: #000;
}
  
.show {
  display: block !important;
  padding-left: 0px !important;
  
}

.submenu{
  padding-left: 0px !important;
}
.icons-nav{
  font-size: 12px;
    margin: 2px 4px;
}
.text-transform{
  text-transform: capitalize;
}

.submenu {
  display: none;
}

.dropdown-item:hover .submenu {
  display: block;
  margin-top: -10px;
}

#Philippines:hover .submenu{
  margin-top: -12px !important;
  -webkit-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s;
}

.bg-color{
  background-color: #ccc;

}
.border_before::before{
  position: absolute;
  content: '';
  height: 3px;
  width: 148px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
  margin-top: 40px;
}

.border_before::before:hover{
  position: absolute;
  content: '';
  height: 2px;
  width: 148px;
  height: 3px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
  margin-top: 40px;

}
.card-height{
  min-height: 135px;
}
.list-none{
  list-style: none;
  padding: 0;
}

.inlinefix{
  display: inline-flex;
  align-items: baseline;
}
.f-17{
  font-size: 17px !important;
}
.f-18{
  font-size: 18px !important;
}
.f-19{
  font-size: 19px !important;
}
.f-20{
  font-size: 20px !important;
}
.bg-coloringrideant{
  /* background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%); */
    background: #204082;
    opacity: 0.9;
    color:#fff;

}
.bg-coloringrideant .p{
  color: #fff !important;
}
.exam-text{
  font-size: 16px !important;
  font-weight: 600 !important;
}
.third-section p{
  text-transform: capitalize !important;
}
.card-body p{
  text-transform: capitalize !important;
}

.exam-bg{
  background: aliceblue;
}
/* mobile view */
@media only screen and (max-width: 480px) {
  .mobile-navflex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    overflow: auto;
  }

}

@media handheld, only screen and (max-width: 640px) {
  .mobile-navflex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    overflow: auto;
  }

}

#section1 {
  height: 100px;
  background-color: pink;
}

#section2 {
  height: 100px;
  background-color: yellow;
}

.news-bg{
  background-color: #b2d6dc8f;
  padding: 20px 0px 20px 6px;
  border-radius: 4px;
  font-size: 21px;
  font-weight: 700;
  color: #181848;
}
.news-paragraphbg{
  background: #cccccc24;
  padding: 5px 5px;
  border: 1px solid #b9c2c91f;

}
.latest-news h3{
  font-size: 19px !important;
  font-weight: 700;
  color: #ccc !important;
  background: #010a1a;
  padding: 15px 8px;
  font-style: italic;
}
.row1{
  border: 1px solid #cccccc4d;
}
.row1 .crdr1{
  padding: 8px 8px;
  border-bottom: 1px solid #cccccc4d;
}
.section5 h3 {
  margin: 0;
}

.news-paragraph{

    font-size: 17px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}
.news-textbg{
 background: #cccccc47;
  padding: 6px 8px;
  
}


.f-15{
  font-size: 14px;
}
.viewallbutton{
  background-color: #FF9800 !important;
  font-weight: 700;
}

.abt-section-news h3{
text-transform: capitalize;
}
